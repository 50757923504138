const en = {
  ohmEnergy: "OHM ENERGY",
  contactUs: "contact us",
  allProjects: "All PROJECTS",
  countries: "Countries",
  satisfiedCustomers: "Satisfied Customers",
  mWhProduced: "mWh Produced",
  solarPowerPlantCalculator: "Solar Power Plant Calculator",
  calculatorDescription:
    "Use our calculator to discover the required system capacity, cost and other details you need. Simply input your monthly electricity expenditure in dollar (USD), and the calculator will handle all the necessary computations for you.",
  selectRegion: "Select your region",
  tbilisi: "tbilisi",
  adjara: "adjara",
  racha: "racha",
  kakheti: "kakheti",
  imereti: "imereti",
  aphkhazeti: "aphkhazeti",
  guria: "guria",
  svaneti: "svaneti",
  lechkhumi: "lechkhumi",
  samtskheJavakheti: "samtskhe-javakheti",
  samegrelo: "samegrelo",
  qvemoQartli: "qvemo-qartli",
  shidaQartli: "shida-qartli",
  mtskhetaMtianeti: "mtskheta-mtianeti",
  chooseStatus: "CHOOSE STATUS",
  selectPlaceForPanels: "Select place for panels",
  onGround: "On ground",
  slopingRoof: "Sloping roof",
  straightRoof: "Straight roof",
  monthlyCost: "Monthly cost:",
  calculate: "calculate",
  enterMonthlyCost:
    "Enter how much you pay for electricity every month, in USD",
  calculation: "Calculation",
  forPerson: "for Person",
  forCompany: "for Company",
  chargePerKWh: "Charge per 1 kWh of electricity: ",
  installedPower: "Installed power of the plant in kilowatts: ",
  monthlyConsumption: "Monthly electricity consumption in kW*hour: ",
  requiredArea: "Required area: ",
  projectCost: "Project cost: ",
  currency: "USD",
  customersComments: "CUSTOMERS COMMENTS",
  siteMap: "Site Map",
  aboutUs: "ABOUT US",
  ourProjects: `OUR PROJECTS`,
  projects: "PROJECTS",
  blog: "BLOG",
  partners: "PARTNERS",
  solarCalculator: "SOLAR CALCULATOR",
  address: "Address",
  addressStr: "11a Paliashvili Street",
  addressMapLocation: "Tbilisi, Georgia",
  workingHours: "Working Hours",
  workingHoursDetail: "Mon - Fri, 10:00 - 19:00",
  contact: "Contact",
  contactNumber: "+995 32 280 00 33",
  contactEmail: "info@ohmenergy.ge",
  followUs: "Follow us",
  copyright: "© OHM ENERGY SOLUTIONS 2024",
  largeScale: "LARGE SCALE",
  commercial: "COMMERCIAL",
  residential: `RESIDENTIAL`,
  projectsLowerCase: "projects",
  description: "Description",
  capicity: "Capicity",
  location: "Location",
  SolarModule: "Solar Module",
  inverter: "Inverter",
  annualElectricityProduction: "Annual electricity production",
  ourPartners: `OUR PARTNERS`,
  partnersCanadianSolarDescription: `Canadian Solar Inc. was founded in 2001 in Canada and is one of the world’s largest solar technology and renewable energy companies. Over the past 23 years, Canadian Solar has successfully delivered over 125 GW of premium quality, solar photovoltaic modules to customers. Since entering the project development business in 2010, Canadian Solar has developed, built, and connected over 10 GWp of solar power projects and 3.3 GWh of battery energy storage projects across the world.`,
  partnersAeSolarDescription: `AESOLAR foundation is built on the excellence of German engineering, which drives it to lead the way in solar energy. Company began its journey in 2003, inspired by Dr Alexander Maier and his visionary brothers, with a clear mission: to use solar energy to power our future. As a Tier 1 Manufacturer, they provide high quality photovoltaic modules renowned for their stability, durability and reliability. Innovation is at the heart of everything they do. `,
  partnersAstroDescription: `Under the CHINT Group, Astronergy is an intelligent manufacturing enterprise focusing on photovoltaic cells and modules. Founded in 2006, it is one of the earliest private enterprises in China to set foot in the photovoltaic field. And it is a pioneer in n-type TOPCon PV modules.`,
  partnersHuaweiDescription: `By the end of 2023, Huawei FusionSolar has helped customers generate 1109.5 billion kWh of green electricity, curbing 527 million tons of carbon dioxide, which is equivalent to planting 719 million trees.
Conversion note: 1. Conversion coefficient of electricity carbon emissions – 1 kWh electricity is equivalent to 475 g CO₂ (global average). Source: IEA Global Energy & CO₂ Status Report 2018; 2: Lifetime CO₂ absorption of trees (equivalent number of planted trees) – A tree absorbs 18.3 kg of CO₂ a year, and each tree has a 40-year lifespan. Source: Open data of the North Carolina State University website.`,
  partnersSmaDescription: `As a leading global specialist for photovoltaic system technology, SMA is setting the standards today for the decentralized and renewable energy supply of tomorrow. More than 3,500 SMA employees in 20 countries have devoted themselves to this task. Our innovative solutions for all photovoltaic applications and our unsurpassed service offer our customers worldwide greater independence in meeting their energy needs.`,
  partnersKbeDescription: `Based in Berlin, we are part of a group of medium-sized, group-independent companies. All facilities are certified in accordance with IATF 16949:2016. KBE Elektrotechnik GmbH was founded in 1980 and has continued to grow ever since. Today, the group employs more than 350 people in all. The current annual capacity of the group’s wire and cable production totals approximately 2.0 million km or 35,000 tons of copper per year.`,
  partnersSchneiderElectricDescription: `Schneider Electric is a global industrial technology leader bringing world-leading expertise in electrification, automation and digitization to smart industries, resilient infrastructure, future-proof data centers, intelligent buildings, and intuitive homes. Anchored by their deep domain expertise, they provide integrated end-to-end lifecycle AI enabled Industrial IoT solutions with connected products, automation, software and services, delivering digital twins to enable profitable growth for their customers. `,
  partnersHikraDescription: `Hikra soon`,
  partnersStaubliDescription: `Stäubli is a mechatronics solutions provider with three core activities: Connectors, Robotics and Textile. Stäubli is an international group that currently operates in 29 countries, with agents in 50 countries on four continents. Global workforce of 5,500 shares a commitment to partnering with customers in nearly every industry to provide comprehensive solutions with longterm support.`,
  partnersKiracDescription: `Kıraç Metal Ürünleri San. ve Tic. Ltd. Şti. was established in 2002 in order to manufacture cable trays. The Company has completed successful projects with its experienced and dynamic team. Its main manufacturing facility is located in Eskişehir Organized Industrial Zone. The facility is established on a total area of 20,250 sq.m having an indoors space of 12,150 sq.m. Today, Kıraç Metal produces Solar Panel Handling and Mounting Systems, Cable Trays and Ladders, Supporting Components and Fittings plus Industrial-Type Electrical Panels with a manufacturing capacity of 1,000 Tones/Month. The facility is also designed to incorporate flexibility to adjust its manufacturing flow for tailored products. As one of the leading global manufacturers, Kıraç̧ Metal is the choice of clients for its high quality and customer oriented product and services, on time delivery policy and its pre/post-sales services`,
  partnersHisDescription: `HIS Renewables is one of the leading European providers of system solutions for the integration of renewable energies. Whether integrated photovoltaics, storage solutions, self-consumption optimization or EV charging technology: All HIS solutions have been developed at the company's site in Germany for more than 25 years and manufactured on state-of-the-art machinery and equipment.`,
  partnersSuntreeDescription: `SUNTREE Electirc production's DC circuit breaker, DC isolating switch, DC fuse, busbar chamber, waterproof switch and other products with leading technical level in this field. Company is the first domestic company with CE, CB, IEC, Nemko, SAA, TUV, ISI and CCC certification. Their products are widely used by domestic famous enterprises, and it has become a OEM partner of many international famous brands.`,
  partnersAbbDescription: `ABB is a technology leader in electrification and automation, enabling a more sustainable and resource-efficient future. The company’s solutions connect engineering know-how and software to optimize how things are manufactured, moved, powered and operated. Building on more than 140 years of excellence, ABB’s 105,000 employees are committed to driving innovations that accelerate industrial transformation.`,
  partnersK2Description: `As a roof-mounting specialist, K2 is focusing on developing and optimising innovative mounting systems, while always keeping their feet on the ground. Through constant exchange with their customers, they can offer practical, long-lasting and secure solutions.`,
  partnersMunichreDescription: `Munich Re covers the entire value chain of reinsurance, primary insurance, and insurance-related risk solutions. Click to meet the company behind the name.`,
  partnersArielreDescription: `Ariel Re provides its clients with a broad range of innovative reinsurance solutions and services through our offices in Bermuda, London and Hong Kong. We are a multi-line, specialty reinsurer meeting the business needs of a diverse client base. Ariel Re operates principally through Syndicate 1910 at Lloyd’s but also offers access to Lloyd’s Europe via Syndicate 5336.

Originally founded in 2005, Ariel Re has a long record of outstanding performance, consistently outperforming both the Lloyd’s and Bermuda markets. Lloyd’s data shows Syndicate 1910 to be the third most profitable syndicate in the Lloyd’s market for the period 2011 to 2021.`,
  partnersTuvRheinlandDescription: `TÜV Rheinland stands for safety and quality in virtually all areas of business and life. Founded more than 150 years ago, the company is one of the world’s leading testing service providers with more than 20,870 employees and annual revenues of around 2.3 billion euros. TÜV Rheinland’s highly qualified experts test technical systems and products around the world, support innovations in technology and business, train people in numerous professions and certify management systems according to international standards. In doing so, the independent experts generate trust in products as well as processes across global value-adding chains and the flow of commodities. Since 2006, TÜV Rheinland has been a member of the United Nations Global Compact to promote sustainability and combat corruption. `,
  partnersUnisonDescription: `Since 2011 JSC Insurance Company UNISON , established by Georgian capital, has been serving both – individuals and companies offering full range of individual and corporate services on the market.

Consistent work and professionalism led to our success.  At the outset  of the activity UNISON has been one of the most stable and sustainable companies on the Georgian market and is among the top five most popular insurance companies.`,
  partnersGedfDescription: `JSC “Georgian Energy Development Fund” is a joint-stock company, which is founded by Ministry of Economy and Sustainable Development of Georgia. 100% of Fund’s shares are in the state ownership JSC “Georgian Energy Development Fund” is managed by the Ministry of Economy and Sustainable Development of Georgia

The company has registered according to the Decree of Government of Georgia N1564 dated 08th of December 2010 and according to the order of Ministry of Economy and Sustainable Development of Georgia N1-1/1950 dated 10th of December 2010.`,
  partnersGeffDescription: `SEIZE THE CHANCE TO PARTICIPATE IN THE EU4BUSINESS-EBRD CREDIT LINE AND
ENHANCE PROSPERITY OF YOUR BUSINESS. JOIN THE EU4BUSINESS-EBRD CREDIT LINE,
JOINT INITIATIVE OF THE EUROPEAN UNION AND THE EUROPEAN BANK FOR
RECONSTRUCTION AND DEVELOPMENT (EBRD).
ELIGIBLE PARTICIPANTS HAVE THE OPPORTUNITY TO RECEIVE CASHBACKS OF UP TO
10-15% OF THE LOAN AMOUNT.`,
  aboutUsDescription: `OHM ENERGY was founded in 2016 as a provider of design and construction services. The company helps customers to make quality decisions in the field of energy and is one of the leading companies in the region. The organization has implemented a total of more than 80 megawatts of domestic, commercial and large-scale projects in Georgia, Armenia and Uzbekistan.`,
  vision: `OUR VISION`,
  aboutUsVision: `Our vision is to integrate the renewable energy sector into the economy with high standard methodologies, services and equipment with efficient, stable and future-oriented solutions.`,
  irakliUbilava: `Irakli Ubilava`,
  irakliBabunashvili: `Irakli Babunashvili`,
  davitMarkhulia: `Davit Markhulia`,
  davitBardavelidze: `Davit Bardavelidze`,
  operationsManager: "Operations Manager",
  salesManager: `Manager of Sales`,
  managingPartner: "Managing Partner",
  technicalDirector: "Technical Director",
  CustomersCommentsTitle: `CUSTOMERS COMMENTS`,
  contactPage: "CONTACT PAGE",
  name: "name",
  companyName: `company name`,
  emailAdrress: `email address`,
  countryCode: `country code`,
  phoneNumber: `phone number`,
  sendUsEmail: `CONTACT US`,
  comments: "Leave comment here",
  submit: "Submit",
  kw: "kw",
  kwh: "kW⋅h",
  mwh: "mwh",
  sqm: "sq.m",
  citroenTItle: `Citroën Center Tbilisi`,
  citroenSolarModule: "AE SOLAR",
  citroenLocation: "Tbilisi, Georgia",
  zhinvaliTItle: `Zhinvali`,
  zhinvaliLocation: "Zhinvali, Georgia",
  zhinvaliSolarModule: "---",
  marneuliNurseryTitle: "Association of nurseries",
  marneuliNurseryLocation: "Marneuli, Georgia",
  marneuliNurserySolarModule: "Sunova Solar 420w Full Black",
  mermisiTitle: `College "Mermis"`,
  mermisiLocation: "Tbilisi, Georgia",
  mermisiSolarModule: "---",
  tbilisiHillsTitle: `Tbilisi Hills`,
  tbilisiHillsLocation: "Tbilisi, Georgia",
  tbilisiHillsSolarModule: "---",
  toyotaCenterTitle: `Toyota center`,
  toyotaCenterLocation: "Tbilisi, Georgia",
  toyotaCenterSolarModule: "---",
  altaTitle: `ALTA`,
  altaLocation: "Tbilisi, Georgia",
  altaSolarModule: "---",
  fazisiTitle: `FAZISI`,
  fazisiLocation: "Poti, Georgia",
  fazisiSolarModule: "---",
  residentialHouseTitle: `Residential House"`,
  ResidentialHouseLocation: "Tbilisi, Georgia",
  ResidentialHouseSolarModule: "---",
  mw: "mw",
  phoneNumberValidity: "Please enter a valid number",
  emailValidity: "Please enter a valid email ",
  onlyNumbers: "Enter only numbers",
  tooShort: "",
  rustaviResidentialTitle: "Residential house",
  rustaviResidentialLocation: "Rustai, Georgia",
  rustaviResidentialSolarModule: "--",
  megadorisiTitle: "Megadoors",
  megadorisiLocation: "Tbilisi, Georgia",
  megadorisiModule: "--",
};
export default en;
