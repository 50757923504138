import React from "react";
import { Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TableComponent from "../components/TableComponent";
import MultiItemCarousel from "../components/MultiItemCarousel";
import useFilteredProjectsHook from "../hooks/useFilteredProjectsHook";

const SingleProjectPage = () => {
  const { language } = useSelector((state) => state.language);
  const { translations } = useSelector((state) => state.language);
  const { projectId } = useParams();
  const project = useSelector((state) =>
    state.projects.find((proj) => proj.name.en === projectId)
  );
  const filteredProjects = useFilteredProjectsHook(project.type.en);
  const singleProjectFilteredProjects = filteredProjects.filter(
    (pr, i) => pr !== project
  );

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [projectId]);

  return (
    <Container fluid className="single-project-container">
      {project ? (
        <Row className="single-page-row-container">
          <Row className="single-page-row-1-inner-container">
            <div className="single-page-main-image-container">
              <img
                src={project.imageSrc}
                alt=""
                className="single-page-main-image"
              />
              <div className="single-page-main-image-layer"></div>
            </div>
            <div className="single-page-row-1-name-table-container">
              <div className="single-page-row-1-name-table-inner-container">
                <div className="single-page-row-1-name-container">
                  <h2>{project.name[language]}</h2>
                </div>
                <div className="single-page-row-1-table-container">
                  <TableComponent project={project} language={language} />
                </div>
              </div>
            </div>
          </Row>
          <Row className="single-page-rows-container">
            {project.description || project.videoSrc ? (
              <Row className="single-page-row single-page-row-2">
                {project.description[language].length > 0 && (
                  <div
                    className={`single-page-description-container  ${
                      project.videoSrc ? "set-width-50" : "set-width-100"
                    }`}
                  >
                    <h5>{translations.description}</h5>
                    {project &&
                      project.description[language].map((text, i) => (
                        <p key={i} className="mb-0">
                          {text}
                        </p>
                      ))}
                  </div>
                )}

                {project.videoSrc && (
                  <div
                    className={`single-page-video-container ${
                      project.description ? "set-width-50" : "set-width-100"
                    }`}
                  >
                    <iframe
                      src={project.videoSrc}
                      title="Large scale solar power plant with the power of 3 MW | OHM ENERGY | Armenia #Solar #pv #OHM"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  </div>
                )}
              </Row>
            ) : null}
            <Row className="single-page-row single-page-row-3">
              {project.otherImagesSrc.map((image, i) => (
                <div key={i} className="single-page-row-3-col">
                  <img src={image} alt="" />
                </div>
              ))}
            </Row>
            {singleProjectFilteredProjects.length >= 3 ? (
              <Row className="single-page-carousel-row single-page-row">
                <h6>
                  {project.type[language]} {translations.projectsLowerCase}
                </h6>
                <MultiItemCarousel
                  projects={singleProjectFilteredProjects}
                  language={language}
                />
              </Row>
            ) : <Row className="pt-5"></Row>}
          </Row>
        </Row>
      ) : (
        <Row>
          <div>not found</div>
        </Row>
      )}
    </Container>
  );
};

export default SingleProjectPage;
