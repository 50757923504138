import React from "react";
import { useSelector } from "react-redux";

const CalcComponent = ({ localCalcData, stationPrice, stationPower }) => {
  const {translations} = useSelector(state => state.language);
  
  
  
  const forPerson = (
    <div>
      {" "}
      <h2>{translations.forPerson || 'for Person'}</h2>
      {/* <p><span className="fw-bold">{translations.chargePerKWh}</span>- {translations.currency}</p> */}
      <p><span className="fw-bold">{translations.installedPower}</span> - {stationPower} {translations.kw}</p>
      {/* <p>{translations.monthlyConsumption} - {translations.kwh}</p> */}
      {/* <p><span className="fw-bold">{translations.requiredArea}</span> - {translations.sqm}</p> */}
      <p className="m-0"><span className="fw-bold">{translations.projectCost}</span> - {stationPrice.toFixed(2)} {translations.currency}</p>
    </div>
  );

  
  const forCompany = (
    <div>
      {" "}
      <h2>{translations.forCompany || 'for Company'}</h2>
      {/* <p><span className="fw-bold">{translations.chargePerKWh}</span>- {translations.currency}</p> */}
      <p><span className="fw-bold">{translations.installedPower}</span> - {stationPower} {translations.kw}</p>
      {/* <p>{translations.monthlyConsumption} - {translations.kwh}</p> */}
      {/* <p><span className="fw-bold">{translations.requiredArea}</span> - {translations.sqm}</p> */}
      <p className="m-0"><span className="fw-bold">{translations.projectCost}</span> - {stationPrice.toFixed(2)} {translations.currency}</p>
    </div>
  );


  return (
    <div className="calcComponent-container">
     {localCalcData.personCompany === 'person' ? forPerson : forCompany}
    </div>
  );
};

export default CalcComponent;
